<template>
  <base-layout-two page-title="Financials" page-default-back-link="/more" content-class="background-white">
    <section class="padding-x-16">
      <h1>Financials Credit Card Charges</h1>
    </section>

    <table class="margin-16">
      <thead>
        <tr>
          <td>
            <h6>Date</h6>
          </td>
          <td>
            <h6>Posted Date</h6>
          </td>
          <td>
            <h6>Trans. ID</h6>
          </td>
          <td>
            <h6>Description</h6>
          </td>
          <td>
            <h6>Amount</h6>
          </td>
          <td>
            <h6>Card Holder</h6>
          </td>
          <td>
            <h6>Vendor</h6>
          </td>
          <td>
            <h6>Category</h6>
          </td>
          <td>
            <h6>Notes</h6>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="cc of financials_ccs" :key="cc.id">
          <td>
            <p>{{ cc.Transaction_Date }}</p>
          </td>
          <td>
            <p>{{ cc.Posted_Date }}</p>
          </td>
          <td>
            <p>{{ cc.Transaction_ID }}</p>
          </td>
          <td style="max-width: 400px">
            <p>{{ cc.Description }}</p>
          </td>
          <td>
            <p>{{ cc.Amount }}</p>
          </td>
          <td>
            <p>{{ cc.Card_Holder }}</p>
          </td>
          <td>
            <input v-model="cc.vendor" @blur="updateFinancialsCc(cc)">
          </td>
          <td>
            <select v-model="cc.category" @change="updateFinancialsCc(cc)">
              <option value="advertising">Advertising</option>
              <option value="equipment">Equipment</option>
              <option value="furniture">Furniture</option>
              <option value="meals">Meals</option>
              <option value="shipping">Shipping & postage</option>
              <option value="software">Software & apps</option>
              <option value="supplies">Supplies</option>
              <option value="training">Training & Development</option>
              <option value="travel">Travel</option>
              <option value="utilities">Utilities</option>
              <option value="misc">Misc</option>
              <option value="uncategorized">Uncategorized</option>
            </select>
          </td>
          <td>
            <input v-model="cc.general_notes" @blur="updateFinancialsCc(cc)">
          </td>
        </tr>
      </tbody>
    </table>
  </base-layout-two>
</template>

<script>
import { } from "@ionic/vue";

export default {
  components: {
  },

  computed: {
  },

  data() {
    return {
      financials_ccs: [],
    };
  },

  ionViewWillEnter() {
    this.fetchFinancialsCcs();
  },

  methods: {
    async fetchFinancialsCcs() {
      await this.axios
        .get(
          `${process.env.VUE_APP_API}/financials_ccs`
        )
        .then((response) => {
          this.financials_ccs = response.data.financials_ccs;
          this.financials_ccs = response.data.financials_ccs.sort((a, b) => {
            return (
              new Date(b.Posted_Date) - new Date(a.Posted_Date) || Date(b.Transaction_Date) - new Date(a.Transaction_Date)
            );
          })
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async updateFinancialsCc(financials_cc) {
      console.log(financials_cc)

      const config = {
        method: "patch",
        url: `${process.env.VUE_APP_API}/financials_ccs/${financials_cc.id}`,
        data: financials_cc,
      };

      await this.axios(config)
        .then(() => {
          // console.log(response.data)
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
thead {
  background: #D3D3D3
}

tr:nth-child(even) {
  background: #D3D3D3
}

table thead tr td {
  padding-left: 8px;
  padding-right: 8px;
}

table tbody tr td {
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
}
</style>